.login-theme {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.rbc-event {
  max-height: 20px;
  font-size: 12px;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

/* Image preview toolbar buttons color */
.photo-gallery>* {
  color: white;
}